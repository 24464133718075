import React from 'react'
import * as Component from '../components'

export default () => {
  return (
    <>
      <Component.Schema
        markup={{
          "name": "Hesam Bayat — Collections",
          "description": "TODO: Add page description",
          "url": "https://hesambayat.com/collections",
        }} />
      <Component.Header fixed />
      <div className="container">
        <div className="alignwide">
          <div className="section">
            <Component.ComingSoon />
          </div>
        </div>
      </div>
      <Component.Footer fixed />
    </>
  )
}
